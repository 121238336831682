import PropTypes from 'prop-types';
import { Select } from 'sw-ui';

import Input from 'components/input';
import HotelAnalyticsRow from '../HotelAnalyticsRow';

import {
  LABELS,
  REQUEST_STATUS_LABELS,
  REQUEST_STATUSES,
  THEME,
} from 'bi/constants/hotelBookRequest';

import styles from './index.module.css';

const ChatInfoBlock = (props) => {
  const {
    status,
    isEditing,
    intercomLink,
    changeIntercomLink,
    requestUserId,
    requestUsers,
    setRequestUserId,
    requestStatus,
    selectedRequestAnalytics,
    selectCustomRequestAnalytics,
    accountAnalytics,
  } = props;

  const isConfirmed = status === REQUEST_STATUS_LABELS.Confirmed || requestStatus === REQUEST_STATUSES[1].value;

  const renderUserSelect = () => {
    if (!requestUsers) return null;

    return (
      <div>
        <label>{ LABELS.INITIATOR }</label>
        <Select
          className={ styles.user_selector }
          theme={ THEME.LIGHT }
          hideLabel
          search
          nullable
          label={ LABELS.SELECT_USER }
          items={ requestUsers }
          value={ requestUserId }
          onChange={ (value) => setRequestUserId(value) }
        />
      </div>
    );
  };

  const renderCustomRequestAnalytics = () => {
    if (status !== REQUEST_STATUS_LABELS.Confirmed && requestStatus !== REQUEST_STATUSES[1].value) return null;
    const analytics = accountAnalytics.filter(({ ApplyToTrip }) => ApplyToTrip);
    if (analytics.length === 0) return null;

    return (
      <HotelAnalyticsRow
        analytics={ analytics }
        onChange={ selectCustomRequestAnalytics }
        selectedAnalytics={ selectedRequestAnalytics }
      />
    );
  };

  const renderAnalytics = () => (
    <div className={ styles.selectors }>
      { renderCustomRequestAnalytics() }
    </div>
  );

  return (
    <div className={ styles.chat_info_wrap }>
      <div className={ styles.row }>
        <Input
          type='text'
          field={ LABELS.INTERCOM_LINK }
          placeholder={ LABELS.INTERCOM_LINK }
          value={ intercomLink }
          onChange={ ({ target: { value } }) => changeIntercomLink(value) }
          className={ styles.input }
        />
        { isConfirmed && isEditing ? renderUserSelect() : null }
      </div>
      { renderAnalytics() }
    </div>
  );
};

ChatInfoBlock.propTypes = {
  status: PropTypes.string,
  requestStatus: PropTypes.number,
  isEditing: PropTypes.bool.isRequired,
  changeIntercomLink: PropTypes.func.isRequired,
  intercomLink: PropTypes.string.isRequired,
  setRequestUserId: PropTypes.func.isRequired,
  requestUsers: PropTypes.arrayOf(PropTypes.object),
  requestUserId: PropTypes.string.isRequired,
  selectedRequestAnalytics: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  selectCustomRequestAnalytics: PropTypes.func.isRequired,
  accountAnalytics: PropTypes.arrayOf(PropTypes.object),
};

export default ChatInfoBlock;
