import PropTypes from 'prop-types';

import TextArea from 'components/textarea';
import UploadForm from 'components/UploadForm';

import {
  LABELS,
  REQUIRED_EXTENSIONS,
  TEXT_AREA_HEIGHT,
  UPLOAD_FORM,
} from 'bi/constants/hotelBookRequest';

import styles from './index.module.css';

const AdditionalInfoBlock = (props) => {
  const {
    customTemplate,
    uploadCustomTemplate,
    resetCustomTemplate,
    setAdditionalComment,
    additionalComment,
  } = props;

  const renderCustomTemplateUploader = () => {
    const { uploaded, file } = customTemplate;

    return (
      <UploadForm
        extensions={ REQUIRED_EXTENSIONS }
        invalidExtension={ UPLOAD_FORM.INVALID_EXTENSION }
        chooseFileLabel={ UPLOAD_FORM.CHOOSE_FILE }
        description={ UPLOAD_FORM.DESCRIPTION }
        className={ styles.upload_form }
        onFileAdded={ uploadCustomTemplate }
        onFileRemoved={ resetCustomTemplate }
        uploaded={ uploaded }
        file={ file }
      />
    );
  };

  return (
    <div className={ styles.additional_info }>
      { renderCustomTemplateUploader() }
      <TextArea
        className={ styles.textarea }
        value={ additionalComment }
        placeholder={ LABELS.COMMENT }
        height={ TEXT_AREA_HEIGHT }
        onChange={ setAdditionalComment }
      />
    </div>
  );
};

AdditionalInfoBlock.propTypes = {
  customTemplate: PropTypes.object,
  uploadCustomTemplate: PropTypes.func.isRequired,
  resetCustomTemplate: PropTypes.func.isRequired,
  additionalComment: PropTypes.string,
  setAdditionalComment: PropTypes.func.isRequired,
};

export default AdditionalInfoBlock;
